html, body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-user-drag: none;
  -moz-osx-font-smoothing: grayscale;
}


@media screen and (max-width: 1069px) and (min-width: 735px) {
  html {
    font-size: 13.7142px;
  }
}

@media screen and (max-width: 734px) {
  html {
    font-size: 12.4444px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


